import React, { useContext, useCallback, useEffect } from 'react';
import './answer.scss';
import { useHistory, Link } from 'react-router-dom';
import { ProgressContext } from 'components/utils/QuizProgress';

const Answer = ({ payload, callback, nextButton }) => {
	const { explanation, explanationImage, answer } = payload;
	const { quizProgress } = useContext(ProgressContext);

	const chosen = quizProgress.progress[quizProgress.progress.length - 1];

	const history = useHistory();
	const handleOnClick = useCallback(() => history.push('/'), [history]);

	useEffect(() => {
		window.addEventListener("keyup", upHandler);
		return () => {
			window.removeEventListener("keyup", upHandler);
		}
	});

	const upHandler = ({key}) => {
		if (key === "Escape"){
			window.removeEventListener("keyup", upHandler);
			handleOnClick();
		}
	};



	//LOL
	const res =
		(answer === 1 && chosen) || (answer === 0 && !chosen) ? 'goed' : 'fout';

	return (
		<div className='answer'>
			<div className='header'>
				<p></p>
				<h1>{payload.id} / 10</h1>
				<Link to='/'>
					<h1>X</h1>
				</Link>
			</div>
			<div className='answerBlock'>
				<h1>{res}</h1>
				<p>{explanation}</p>
				{explanationImage}
				<button className='standardButton' onClick={callback}>
					{nextButton}
				</button>
			</div>
		</div>
	);
};

export default Answer;
