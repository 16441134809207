import React, { useState } from 'react';
import Youtube from 'react-youtube';
import './youtube.scss';

const opts = {
	height: '100%',
	width: '100%'
};

const videoEnd = () => {};

const YoutubePlayer = ({ videoId }) => {
	const [displayVideo, setDisplayVideo] = useState(false);

	return (
		<div>
			{displayVideo ? (
				<Youtube
					className='youtubeContainer'
					opts={opts}
					onEnd={videoEnd}
					videoId={videoId}
				/>
			) : (
				<button
					className='startButton'
					onClick={() => {
						setDisplayVideo(true);
					}}
					style={{
						backgroundImage: `url(${process.env.PUBLIC_URL}/images/placeholder2.png)`
					}}
				>
					<h1>START DE VIDEO</h1>
				</button>
			)}
		</div>
	);
};

export default YoutubePlayer;
