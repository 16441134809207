const links = [
	{
		description: 'Melden',
		intro: 'Wat te doen als je iemand je digitaal heeft genept. ',
		url: 'https://www.meldknop.nl/'
	},
	{
		description: 'Gratis hulp',
		intro: 'Voorkom erger, onderneem actie. Gratis hulp als je bent genept. ',
		url:
			'https://www.slachtofferhulp.nl/gebeurtenissen/fraude/phishing/?gclid=EAIaIQobChMI6_3KuLCU5wIVhOF3Ch2ToAcpEAAYASAAEgIonfD_BwE '
	},
	{
		description: 'Fraudehelpdesk',
		intro:
			'Denk je slachtoffer te zijn geworden van cyberfraude? Neem contact op met de Fraudehelpdesk.',
		url: 'https://www.fraudehelpdesk.nl/'
	}
];

export default links;
