import React, {  useEffect, useState } from 'react';
import usePageContent from 'utils/pageChange';
import Heading from 'components/Heading';

import './info.scss';
import links from 'config/links';
import links2 from 'config/links2';
import links3 from 'config/links3';


const Info = () => {


	const { pageContent: page } = usePageContent();
	const [tabIndex, setTabIndex] = useState(false);

	useEffect(() => {
		console.log('---> info remove', page.current.link.replace('/', ''));
		if (page.current.link.replace('/', '') === "info"){
			setTabIndex(false);			
		}else{
			setTabIndex(true);
		}
	})



	return (
		<div className='info'>
			<Heading title='links' />


			<div className='links'>
				<div className='scroll'>
					<h2>Ik ben of ken iemand die digitaal is genept</h2>
					{links.map(({ description, intro, url }) => {


						const label = `${url}  opent een website in een nieuwe tablad`;
 
						return (
							<div tabindex={tabIndex} key={url}>
								<a tabIndex={tabIndex} href={url} target='_blank'  aria-label={label} rel='noopener norefferer'>
									<div>
										<h3>{description} -</h3>
										<p>{intro}</p>
									</div>
									<div>
										<h4>&gt;</h4>
									</div>
								</a>
							</div>
						);
					})}
					<h2>Handige checks en antwoorden op vragen</h2>
					{links2.map(({ description, intro, url }) => {
						return (
							<div key={url}>
								<a tabindex={tabIndex} href={url} target='_blank' rel='noopener norefferer'>
									<div>
										<h3>{description}</h3>
										<p>{intro}</p>
									</div>
									<div>
										<h4>&gt;</h4>
									</div>
								</a>
							</div>
						);
					})}
					<h2>Meer informatie</h2>
					{links3.map(({ description, intro, url }) => {
						return (
							<div key={url}>
								<a tabindex={tabIndex} href={url} target='_blank' rel='norefferer'>
									<div>
										<h3>{description}</h3>
										<p>{intro}</p>
									</div>
									<div>
										<h4>&gt;</h4>
									</div>
								</a>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Info;
