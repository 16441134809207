import React, { useEffect, useState } from 'react';
import Animate from 'components/utils/Carousel3d/Animate';
import useWindowDimensions from 'utils/windowDimensions';
import usePageContent from 'utils/pageChange';

import Tips from 'components/pages/Tips';
import Quiz from 'components/pages/Quiz';
import Info from 'components/pages/Info';
import Download from 'components/pages/Download';

import Parser from 'html-react-parser';
import './carousel3d.scss';
import cnfg from 'config/globals';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');

// this thing simulate's a animated 3d kube with only 2 faces
const Carouesel3d = props => {
	// use context as databinding
	const { pageContent: page } = usePageContent();

	// react is bitching about this in the console
	const { _LEFT, _RIGHT, _FIRSTDIV, _SECONDDIV } = cnfg;

	// create random ID's for the face divs.
	// it's needed to tell anime to anime.
	// ofc we could use firstchild, lastchild etc, but this is
	// easier for now. We only need 2 divs tho.
	const faces = {
		face1: btoa(Math.random()).substring(0, 12),
		face2: btoa(Math.random()).substring(0, 12)
	};

	const [state, setState] = useState({
		direction: _LEFT,
		frontDiv: _FIRSTDIV,
		content1: '',
		content2: '',
		faceIdUpdated: 1,
		face1Colors: page.colors,
		face2Colors: page.colors,
		face1Border: false,
		face2Border: false,
		colors: {}
	});

	const { width } = useWindowDimensions();
	const speed = 1500;
	const delay = props.delay;
	const type = props.type;

	// set pagecontent when route has changed
	useEffect(() => {
		const current = page.current.link.replace('/', '');
		// ReactGA.pageview(window.location.pathname + window.location.search);

		// console.log(current);
		
		// debug this
		if (page.previous) {
			if (
				current === page.previous.to
			)
				return;
		} else {
			page.previous = page.current;
		}

		// if page has not changed, return
		// if (page.previous != null && page.current.id === page.previous.id)
		// return () => {};
		let tmpComponent = null;

		// decide which direction the faces should move
		const direction = page.previous
			? page.current.id > page.previous.id
				? _RIGHT
				: _LEFT
			: null;

		// determine which face should move to front
		const frontDiv = state.frontDiv === _FIRSTDIV ? _SECONDDIV : _FIRSTDIV;

		// litle faceborder fix, because tips doesn't have a content vorder;
		const faceBorder = !(type === 'content' && page.current.link === '/tips');

		// add content
		if (type === 'header') {
			tmpComponent = <h1>{Parser(page.current.title)}</h1>;
		}

		if (type === 'footer') {
			tmpComponent = <p>{Parser(page.current.footerText)}</p>;
		}

		if (type === 'content') {
			if (current === 'home' || current === '')
				tmpComponent = <Quiz {...props} />;
			if (current === 'quiz') tmpComponent = <Quiz {...props} />;
			if (current === 'tips') tmpComponent = <Tips />;
			if (current === 'info') tmpComponent = <Info />;
			if (current === 'download') tmpComponent = <Download />;
		}

		// console.log(page.previous);

		// only change state of the content that has to be changed.
		// else you get glitches & flickerings
		if (
			(frontDiv === _FIRSTDIV && direction === _RIGHT) ||
			(frontDiv === _FIRSTDIV && direction === _LEFT)
		) {
			setState({
				...state,
				direction,
				frontDiv,
				content2: tmpComponent,
				faceIdUpdated: 2,
				face1Colors: page.current.colors[type],
				face2Border: faceBorder,
				pageName2: current
			});
		} else {
			setState({
				...state,
				direction,
				frontDiv,
				content1: tmpComponent,
				faceIdUpdated: 1,
				face2Colors: page.current.colors[type],
				face1Border: faceBorder,
				pageName1: current
			});
		}
	}, [page]);

	// animate when state is updated
	useEffect(() => {
		Animate(state.frontDiv, speed, delay, state.direction, faces);
	}, [state]);

	return (
		<div className={`carousel3d ${type} ${props.className}`}>
			<div
				id={faces.face1}
				className={`${state.face1Border ? 'hasBorder' : 'hasNoBorder'} ${
					state.pageName1
				}Border`}
				style={state.face2Colors}
			>
				{state.content1}
			</div>
			<div
				id={faces.face2}
				className={`${state.face2Border ? 'hasBorder' : 'hasNoBorder'} ${
					state.pageName2
				}Border`}
				style={state.face1Colors}
			>
				{state.content2}
			</div>
		</div>
	);
};
export default Carouesel3d;
