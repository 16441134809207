const [blue, red, green, roze, white] = [
	'#4068b2',
	'#ed1b32',
	'#4dbfa3',
	'#f8c2d9',
	'#fff',
	'#000'
];

const pages = [
	{
		id: 0,
		to: 'home',
		link: '/home',
		title: 'ECHT NEP',
		footerText:
			'Laat jij je digitaal neppen? Blijf scherp en check voor je klikt. <br /> Weet waar je het over hebt en deel die belangrijkste tips',
		menuTitle: 'QUIZ',
		colors: {
			header: { color: red, backgroundColor: roze },
			content: { color: roze, backgroundColor: green },
			footer: { color: white, backgroundColor: blue },
			banner: { color: roze, backgroundColor: red },
			borderColor: green
		}
	},
	{
		id: 1,
		to: 'tips',
		link: '/tips',
		title: 'TIPS!',
		footerText:
			'Laat jij je digitaal neppen? Blijf scherp en check voor je klikt. <br /> Weet waar je het over hebt en deel die belangrijkste tips',

		menuTitle: 'TIPS',
		colors: {
			header: { color: blue, backgroundColor: green },
			content: { color: red, backgroundColor: blue },
			footer: { color: roze, backgroundColor: red },
			banner: { borderColor: red, backgroundColor: roze },
			borderColor: 'none'
		}
	},
	{
		id: 2,
		to: 'info',
		link: '/info',
		title: 'LINKS!',
		footerText:
			'Laat jij je digitaal neppen? Blijf scherp en check voor je klikt. <br /> Weet waar je het over hebt en deel die belangrijkste tips',

		menuTitle: 'LINKS',
		colors: {
			header: { color: roze, backgroundColor: red },
			content: { color: red, backgroundColor: red },
			footer: { color: blue, backgroundColor: green },
			banner: { color: roze, backgroundColor: blue },
			borderColor: roze
		}
	},
	{
		id: 3,
		to: 'download',
		link: '/download',
		title: 'INFO!',
		footerText:
			'Laat jij je digitaal neppen? Blijf scherp en check voor je klikt. <br /> Weet waar je het over hebt en deel die belangrijkste tips',

		menuTitle: 'INFO',
		colors: {
			header: { color: green, backgroundColor: blue },
			content: { color: blue, backgroundColor: blue },
			footer: { color: red, backgroundColor: roze },
			banner: { color: blue, backgroundColor: green },
			borderColor: red
		}
	}
];

export default pages;
