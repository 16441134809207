import React, {  useEffect, useState } from 'react';
import tips from 'config/tips';
import './tips.scss';
import Parser from 'html-react-parser';
import usePageContent from 'utils/pageChange';

import Collapse from 'rc-collapse';

const Tips = () => {
	const Panel = Collapse.Panel;
	const { pageContent: page } = usePageContent();
	const [tabIndex, setTabIndex] = useState(false);

	let i = 0;

	const changed = subheader => {
		if (typeof subheader !== 'undefined') {
			const header = document.getElementById(subheader.replace(/\s/g, ''));
			const h = document.getElementById('h');
			const container = document.getElementById('main');

			setTimeout(() => {
				container.scrollTo({
					top: header.offsetTop - h.clientHeight - 8,
					behavior: 'smooth'
				});
			}, 200);
		}
	};


	useEffect(() => {
		// console.log('---> tips remove', page.current.link.replace('/', ''));
		if (page.current.link.replace('/', '') === "tips"){
			setTabIndex(false);			
		}else{
			setTabIndex(true);
		}
	})


	return (
		<div className='tips'>
			<div className='header' id='h'>
				<h1>TIPS</h1>
			</div>
			<div className='main' id='main'>
				<Collapse ariaHidden="true" accordion={true} showArrow={false} onChange={changed}>
					{tips.map(({ header, subheader, content }) => {
						return (
							<Panel disabled={tabIndex} header={head(header, ++i)} key={header}>
								<h3>{Parser(subheader)}</h3>
								<p>{Parser(content)}</p>
							</Panel>
						);
					})}
				</Collapse>
			</div>
		</div>
	);
};

const head = (header, i) => {
	return (
		<div className='tipsHeader' id={header.replace(/\s/g, '')}>
			<h2>tip {i}</h2>
			<p>{header}</p>
		</div>
	);
};

export default Tips;
