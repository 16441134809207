import React from 'react';
import './ByPassNav.scss';

const ByPassNav = (props) => {
    const anchor = React.useRef()
    const [sr, setSr] = React.useState('u-sr-only');

    const onFocus = () => { setSr('') }
    const onBlur = () => { setSr('u-sr-only') }

return (                
		<section className={`c-bypass-navigation ${sr}`} js-bypass>
			<button className="bypass-navigation__link"
                onClick={props.action()} 
                ref={anchor} 
                onFocus={onFocus}
                onBlur={onBlur}
                aria-label='Direct naar de content'>
				Direct naar de content
			</button>
		</section>
	);
};

export default ByPassNav;
