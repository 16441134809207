import React, { useEffect, useState } from 'react';
import useWindowDimensions from 'utils/windowDimensions';
import './banner.scss';
import usePageContent from 'utils/pageChange';

const Banner = props => {
	const { pageContent: page } = usePageContent();
	let { width: w, height: h } = useWindowDimensions();

	const appContainer = document.getElementById('app');

	if (w > 500) w = 500;
	if (h > 800) h = 800;

	if (appContainer) {
		let wp = parseInt(
			window.getComputedStyle(appContainer).maxWidth.replace('px', '')
		);
		let hp = parseInt(
			window.getComputedStyle(appContainer).maxHeight.replace('px', '')
		);

		if (w > wp) w = wp;
		if (h > hp) h = hp;
	}

	const j = 400;
	let text = '   #echtnep  ';
	let echtnep = '';
	for (let i = 0; i < j; i++) {
		echtnep += `  ${text}   `;
	}

	let d = `m0,0l${w - 32},0l0,${h}l-${w - 8},0l0,-${h}`;

	// let d = `m0,0l${w - 8},0l0,${h}l-${w - 8},0l0,-${h}l${w},0l${0},${h}`;
	let viewbox = `0 0 ${w} ${h}`;

	const [state, setState] = useState({
		d,
		viewbox,
		color: page.current.colors.banner.color
	});

	useEffect(() => {
		const appContainer = document.getElementById('app');

		if (w > 500) w = 500;
		if (h > 800) h = 800;

		if (appContainer) {
			let wp = parseInt(
				window.getComputedStyle(appContainer).maxWidth.replace('px', '')
			);
			let hp = parseInt(
				window.getComputedStyle(appContainer).maxHeight.replace('px', '')
			);

			if (w > wp) w = wp;
			if (h > hp) h = hp;

			// console.log('wow');
		}

		setState({
			...state,
			color: page.current.colors.banner.color,
			backgroundColor: page.current.colors.banner.backgroundColor,
			// d: `m0,0l${w - 8},0l0,${h}l-${w - 8},0l0,-${h}l${w},0l${0},${h}`,
			d: `m0,0l${w - 8},0l0,${h}l-${w - 8},0l0,-${h}`,
			viewbox: `12 -12 ${w - 32} ${h + 24}`
		});
	}, [page, w]);

	// console.log('render');
	return (
		<div
			className={`banner ${page.current.to}B`}
			role='banner'
			// style={{
			//     backgroundColor: state.backgroundColor,
			//     transition: `backgroundolor`
			// }}
		>
			<svg
				id='canvas'
				xmlns='http://www.w3.org/2000/svg'
				version='1.1'
				// preserveAspectRatio="none"
				className='scaling-svg'
				viewBox={state.viewbox}
				width={w}
				height={h}
			>
				<path
					id='curve'
					d={state.d}
					// d="m10,10l380,0l0,380l-380,0l0,-380z" // this was the original
					fill='none'
				></path>
				<text className={`text ${page.current.to}B`}>
					<textPath href='#curve' id='tt' startffset='0'>
						{echtnep}
						<animate
							attributeName='startOffset'
							from='-50%'
							to='0%'
							begin='0s'
							dur='120s'
							repeatCount='indefinite'
							keyTimes='0;1'
							calcMode='spline'
							keySplines='0.3 0.2 0.7 0.8'
						/>
					</textPath>
				</text>
			</svg>
		</div>
	);
};

export default Banner;
