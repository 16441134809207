import * as animationData from 'config/anim1/anim1.json';
import React, { useContext, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import { useLocation, Link } from 'react-router-dom';
import { QuizContext } from '../../QuizContext';
import { ProgressContext } from 'components/utils/QuizProgress';
import Answer from './quiz/Answer';
import Question from './quiz/Question';
import Share from 'components/utils/Share';
import usePageContent from 'utils/pageChange';

import './quiz.scss';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-000000-01');

const Quiz = props => {
	const [progress, setProgress] = useState(0);
	const [countCorrect, setCountCorrect] = useState(0);
	const [resultText, setResultText] = useState(
		'Check de tips om baas te worden over je online gegevens. Zorg dat je vrienden baas worden over hun online gegevens en deel de quiz.'
	);
	const [fullscreen, setFullscreen] = useState(false);
	const [showResult, setShowResult] = useState(false);
	const [tabIndex, setTabIndex] = useState('0');
	const [intro, setIntro] = useState(true);
	const [displayAnswer, setdisplayAnswer] = useState(false);
	const { quizQuestions } = useContext(QuizContext);
	const { quizProgress, setQuizProgress } = useContext(ProgressContext);
	const { pageContent: page } = usePageContent();

	let location = useLocation();

	useEffect(() => {
		setProgress(0);
		setIntro(true);
		setFullscreen(false);
		setdisplayAnswer(false);
		setTabIndex('0');


		return () => {

			// console.log('---> quiz remove', page.current.link.replace('/', ''))
			// console.log(`i'm getting removed`);
			// setTabIndex('-1');
			if (page.current.link.replace('/', '') === "home"){
				setTabIndex('0');			
			}else{
				setTabIndex('-1');
			}
		}


	}, [location]);

	const handleAnswer = () => {
		setdisplayAnswer(true);

		let countCorrect = 0;

		for (let i = 0; i < quizProgress.progress.length; i++) {
			let chosen = quizProgress.progress[i];
			let answer = quizQuestions[i].answer;

			if ((answer === 1 && chosen) || (answer === 0 && !chosen)) {
				countCorrect++;
			}
		}

		setCountCorrect(countCorrect);
		if (countCorrect < 4) {
			setResultText(
				'Check de tips om baas te worden over je online gegevens. Zorg dat je vrienden baas worden over hun online gegevens en deel de quiz.'
			);
		} else if (countCorrect => 5 && countCorrect <= 7) {
			setResultText(
				'Blijf scherp en check de tips. Zorg dat je vrienden baas worden over hun online gegevens en deel de quiz.'
			);
		} else {
			setResultText(
				'Jij bent baas over je online gegevens. Weet jij of jouw vrienden ook net zo goed zijn als jij?'
			);
		}
	};

	const handleNextQuestion = () => {
		setdisplayAnswer(false);

		if (quizProgress.progress.length === quizQuestions.length) {
			setShowResult(true);
			// ReactGA.pageview(`quiz/end/${countCorrect}`);
		} else {
			setProgress(quizProgress.progress.length);

			// ReactGA.pageview('quiz/' + quizProgress.progress.length);

			setQuizProgress({
				...quizProgress,
				disabled: false
			});
		}
	};

	const retry = () => {
		setProgress(0);
		setShowResult(false);
		setQuizProgress({ progress: [], disabled: false });
	};

	const toggleFullscreen = () => {
		setFullscreen(true);
		setQuizProgress({ progress: [], disabled: false });

		setTimeout(() => {
			setIntro(false);
		}, 500);
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			viewBoxSize: '0 500 1200 1200'
		}
	};

	const header =
		location.pathname !== '/fuik' && location.pathname !== '/gratisshoppen'
			? 'ECHT-NEP?'
			: 'GESCHROKKEN?';

	return (
		<div className='quiz'>
			<div className={`header ${fullscreen ? `fullscreen` : ``}`}>
				{fullscreen ? <h1>{header}</h1> : <h2 className='heading red'>{header}</h2>}
			</div>

			<div className={`center ${fullscreen ? `fullscreen` : ``}`}>
				{intro ? (
					<div className={`introduction ${fullscreen ? `fullscreen` : ``}`}>
						<div className='anim'>
							<Lottie options={defaultOptions} height='100%' width='100%' />
						</div>
						<div className='cont'>
							<h2>
								BLIJF BAAS <br />
								OVER JE
								<br /> ONLINE <br />
								GEGEVENS
							</h2>
							<button
								className='standardButton'
								id='startQuiz'
								tabIndex={tabIndex}
								onClick={() => {
									toggleFullscreen();
								}}
							>
								Start de quiz
							</button>
						</div>
					</div>
				) : displayAnswer ? (
					<Answer
						payload={quizQuestions[progress]}
						callback={handleNextQuestion}
						nextButton={
							quizProgress.progress.length < quizQuestions.length
								? `volgende vraag`
								: 'resultaat'
						}
					/>
				) : !showResult ? (
					<Question
						payload={quizQuestions[progress]}
						callback={handleAnswer}
						currentIndex={1}
					/>
				) : (
					<div className='results'>
						<div className='close'>
							<Link to='/'>
								<h1>X</h1>
							</Link>
						</div>

						<h1>Resultaat</h1>
						<h3>Je hebt {countCorrect} van de 10 goed.</h3>
						<p>{resultText}</p>
						{/* <div className='flex'> */}
						<button className='standardButton' onClick={retry}>
							Opnieuw
						</button>
						{/* </div> */}
						<Share />
					</div>
				)}
			</div>

			<div className={`footer ${fullscreen ? `fullscreen` : ``}`}>
				<p>Laat je niet neppen! Weet waar je het over hebt en check de tips!</p>
			</div>
		</div>
	);
};

export default Quiz;
