const links2 = [
    {
        description: "veilig internetten",
        intro:
            "Antwoord op vragen en hulp bij problemen met veilig internetten.",
        url: "https://veiliginternetten.nl/"
    },
    {
        description: "Check de verkoper",
        intro: "Online shoppen of iets bestellen, check de verkoper.",
        url:
            "https://www.politie.nl/aangifte-of-melding-doen/controleer-handelspartij.html"
    },
    {
        description: "Vragen over veiligheid",
        intro: "Voor het antwoord op al jouw vragen over veiligheid.",
        url: "https://www.vraaghetdepolitie.nl/"
    },
    {
        description: "Vragen over privacy",
        intro:
            "Voor het antwoord op al jouw vragen over veiligheid met betrekking tot privacy.",
        url: "https://www.vraaghetdepolitie.nl/privacy"
    }
];

export default links2;
