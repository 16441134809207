import { useState, useContext, useEffect } from 'react';
import pages from 'config/pages';
import { useLocation } from 'react-router-dom';
import { QuizContext } from 'QuizContext';
import { ProgressContext } from 'components/utils/QuizProgress';

// refactoren
export default function usePageContent() {
	const { page } = useContext(QuizContext);
	const [pageContent, setPageContent] = useState(page);
	let location = useLocation();
	const { setQuizProgress } = useContext(ProgressContext);

	useEffect(() => {
		setQuizProgress(false);
		const currentPageInfo =
			pages.filter(val => {
				return location.pathname === val.link;
			})[0] || pages[0];

		if (pageContent.id !== currentPageInfo.id) {
			setPageContent({
				...page,
				previous: pageContent.current,
				current: currentPageInfo
			});
		}
	}, [location]);

	return { pageContent };
}
