import React, { useEffect, useState, useRef } from 'react';
import './DesktopBackground.scss';
import useWindowDimensions from 'utils/windowDimensions';
import { useLocation } from 'react-router-dom';

export default function DesktopBackground(props) {
	let { width: w, height: h } = useWindowDimensions();
	let location = useLocation();

	const [rw, rh] = [1000, 500];

	// const [qStart, setQStart] = useState(false);
	const { fade } = props;
	const ref = useRef();

	const handleQuiz = () => {
		if (document.getElementById('home')) {
			// setQStart(true);
			document.getElementById('home').click();
			setTimeout(() => {
				document.getElementById('startQuiz').click();
			}, 500);
		}
		props.enlarge();
	};

	const header =
		location.pathname !== '/fuik' && location.pathname !== '/gratisshoppen'
			? 'ECHT-NEP?'
			: 'GESCHROKKEN?';

	const [tabIndex, setTabIndex] = useState('0');

	useEffect(() => {
		const appContainer = document.getElementById('app');

		// console.log(fade);
		if (fade){
			setTabIndex ("-1") 
			// console.log(ref.current);
			ref.current.blur();
			// ref.focus(false);	
		}else{
			setTabIndex ("0")
		}


		if (w > rw && h > rh) {
			const handWidth = document.getElementById('hand').clientWidth;
			const correction = (handWidth - 750) / 2;
			const scaleCorrection = 1 + (h - 950) / 965;
			const l = 10 - scaleCorrection * 10;

			// position from right. this one is pretty decent
			appContainer.style.right = `${correction - l * 4}px`;

			// scale the container. this one is not very accurate
			appContainer.style.transform = `scale(${scaleCorrection})`;

			appContainer.style.height = `800px`;
			// this top position looks fine to me
			appContainer.style.top = `${65 - l * 7}px`;

			// console.log('large screen');

		} else {
			appContainer.style.height = '100%';
			appContainer.style.transform = `scale(1)`;
			appContainer.style.right = 'initial';
			appContainer.style.top = `initial`;

			// console.log('mobile');
		}
	}, [w, h, fade]);

	const startFaded =
		location.pathname !== '/fuik' && location.pathname !== '/gratisshoppen'
			? ''
			: 'startFaded';

	return (
		<div
			className={`desktopBanner ${startFaded} ${props.fade ? 'filterme' : ''}`}
			onClick={fade ? props.shrink : null}
		>
			<div className='container'>
				<h1 className='headerr'>{header}</h1>

				<div className='body'>
					<h2>CHECK &amp; BLIJF DE BAAS OVER JE ONLINE GEGEVENS</h2>

					<button
						tabIndex={tabIndex}
						className='standardButton'
						ref={ref}
						onClick={() => {
							handleQuiz();
						}}
					>
						Start de quiz
					</button>
				</div>

				<div className='footer'>
					<div className='footerbody'>
						<h2>
							Laat je niet neppen! Weet waar je het over hebt en check de tips!
						</h2>
					</div>
					<div className='logos'>
						<img className='logo' alt='gemeente rotterdam logo' src='/images/GemRdam_bloklogo_wit.svg' />
						<img className='logo' alt='var (veiligheidsallientie regio rotterdam) logo' src='images/var_wit.png' />
					</div>
				</div>
			</div>

			<img className='hand' id='hand' alt='hand' src='/images/hand3.svg'></img>
		</div>
	);
}
