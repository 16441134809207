const quizQuestions = [
	{
		id: 1,
		vraag: 'PostNL nep',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/Postnl.jpg',
		youtubeId: '',
		answer: 0,
		explanation: 'Het e-mailadres van de afzender is niet van PostNL.',
		explanationImage: ''
	},
	{
		id: 2,
		vraag: 'ICS nep',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/Bijenkorf.jpg',
		// youtubeId: false,
		answer: 0,
		explanation:
			'Deze e-mail is nep. Het e-mailadres van de afzender is niet van de Bijenkorf, de e-mail is niet gepersonaliseerd en taalkundig klopt het niet.',
		explanationImage: ''
	},
	{
		id: 3,
		vraag: 'voorbeeld vraag 2',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/Ticketswap.jpg',
		// youtubeId: false,
		answer: 0,
		explanation:
			'Deze e-mail is nep. Ticketswap vraagt je nooit voor een ticket te betalen via een betaallink of hyperlink in een e-mail. De aankoopknop in de e-mail hoort je naar www.ticketswap.nl te leiden en nooit direct naar een iDeal betaling.',
		explanationImage: ''
	},
	{
		id: 4,
		vraag: 'voorbeeld vraag 1',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/Tikkie.jpg',
		youtubeId: '',
		answer: 1,
		explanation: 'Deze pagina is echt. De URL klopt.',
		explanationImage: ''
	},
	{
		id: 5,
		vraag: 'voorbeeld vraag 1',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/ING_echt.jpg',
		youtubeId: '',
		answer: 1,
		explanation:
			'Deze e-mail is echt. De afzender en URL zijn duidelijk van de bank. Ook is de e-mail gepersonaliseerd.',
		explanationImage: ''
	},

	{
		id: 6,
		vraag: 'voorbeeld vraag 1',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/ING_nep.jpg',
		youtubeId: '',
		answer: 0,
		explanation:
			'Deze e-mail is nep. Het e-mailadres van de afzender is niet van ING. De email is niet gepersonaliseerd en de aanhef algemeen. ING vraagt nooit je account te verifiëren en informatie achter te laten.',
		explanationImage: ''
	},

	{
		id: 7,
		vraag: 'voorbeeld vraag 1',
		header: '<- ECHT swipe NEP ->',
		image: '/images/quiz/Marktplaats.jpg',
		youtubeId: '',
		answer: 0,
		explanation: 'Dit verzoek is nep. De URL klopt niet.',
		explanationImage: ''
	},
	{
		id: 8,
		vraag: 'voorbeeld vraag 1',
		header: 'ING SMS nep',
		image: '/images/quiz/Rabo.jpg', //
		youtubeId: '',
		answer: 0,
		explanation:
			'Deze SMS is nep. De URL klopt niet. Banken vragen nooit via SMS om een account te herstellen.',
		explanationImage: ''
	},
	{
		id: 9,
		vraag: 'voorbeeld vraag 1',
		header: 'Albert Heijn e-mail nep',
		image: '/images/quiz/Appie.jpg',
		youtubeId: '',
		answer: 0,
		explanation:
			'Deze e-mail is nep. Het e-mailadres van de afzender is niet van de Albert Heijn. De Albert Heijn zal nooit zomaar een waardebon weggeven.',
		explanationImage: ''
	},
	{
		id: 10,
		vraag: 'voorbeeld vraag 1',
		header: 'Lidl e-mail nep',
		image: '/images/quiz/Lidl.jpg',
		youtubeId: '',
		answer: 0,
		explanation:
			'Deze e-mail is nep. Het e-mailadres van de afzender is dubieus. Deze ketens zullen nooit zomaar € 500,- aan boodschappen weggeven.',
		explanationImage: ''
	}

	// {
	//     vraag: "placeholder filmpje 3",
	//     header: "<- ECHT swipe NEP ->",
	//     image: "",
	//     youtubeId: "iuK0QVhrYiU",
	//     answer: 0,
	//     explanation: "explanation of question 3",
	//     explanationImage: ""
	// },
	// {
	//     vraag: "placeholder 4 filmpje",
	//     header: "<- ECHT swipe NEP ->",
	//     image: "",
	//     youtubeId: "BnmneAjVrM4",
	//     answer: 0,
	//     explanation: "ochjeetje, explanation of question 4",
	//     explanationImage: ""
	// }
];

export default quizQuestions;
