import * as animationData from 'config/anim1/anim1.json';
import * as animationData2 from 'config/anim2/anim2.json';

import React, { useState, useContext, useEffect, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import YoutubePlayer from './../../utils/Youtube';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { ProgressContext } from 'components/utils/QuizProgress';
import Lottie from 'react-lottie';

import './question.scss';

// todo
// import { QuizContext } from "../../../QuizContext";

const Question = ({ payload, callback, currentIndex }) => {
	const { image, youtubeId } = payload;
	const [index, setIndex] = useState(currentIndex);
	const [swiped, setSwiped] = useState(true);
	const [background, setBackground] = useState(true);
	const { quizProgress, setQuizProgress } = useContext(ProgressContext);


	const history = useHistory();
	const handleOnClick = useCallback(() => history.push('/'), [history]);


	let location = useLocation();

	const changedIndex = i => {	setIndex(i); };

	// get from navigation
	useEffect(() => {

		if (quizProgress.progress.length === payload.id) {
			setSwiped(false);
			if (quizProgress.progress[quizProgress.progress.length - 1]) {
				setBackground(false);
				setIndex(index - 1);
			} else {
				setBackground(true);
				setIndex(index + 1);
			}

			setTimeout(() => {
				callback();
			}, 500);
		}

		return () => {};
	}, [quizProgress]);

	useEffect(() => {
		window.addEventListener("keyup", upHandler);
		return () => {
			window.removeEventListener("keyup", upHandler);
		}
	});

	const upHandler = ({key}) => {
		if (key === "Escape"){
			window.removeEventListener("keyup", upHandler);
			handleOnClick();
		}
	};

	const switching = s => {
		if (s === 1) {
			const el = document.getElementById('qblock');
			el.style.transition = `none`;
			return;
		}

		setBackground(s > 1 ? true : false);
		const el = document.getElementById('qblock');
		const rot = 0 - (s - 1) * 10;
		const scale = s < 1 ? s : 1 - (s - 1);

		el.style.transform = `rotate(${rot}deg) scale(${scale})`;
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			viewBoxSize: '450 0 800 1600'
		}
	};

	// dit is de foute
	const defaultOptions2 = {
		loop: true,
		autoplay: true,
		animationData: animationData2,
		rendererSettings: {
			viewBoxSize: '450 0 800 1600'
		}
	};

	const userAnswered = () => {
		// return div to the original center if div is not swiped outside
		if (index === 1) {
			const el = document.getElementById('qblock');
			el.style.transform = `rotate(0deg) scale(1) `;
			el.style.transition = `all 0.2s ease`;
			return;
		}

		if (swiped) {
			const { progress } = quizProgress;
			setQuizProgress({
				...quizProgress,
				progress: [...progress, index === 0 ? true : false],
				disabled: true
			});
		}
	};

	return (
		<div className='question'>
			<div className={`${background ? `nep` : `answered`}`}>
				<p>{background ? `nep` : `echt`}</p>

				<div className={background ? 'show' : 'hide'}>
					<Lottie // nep
						options={defaultOptions2}
						height='100%'
						width='100%'
					/>
				</div>

				<div className={background ? 'hide' : 'show'}>
					<Lottie // echt
						options={defaultOptions}
						height='100%'
						width='100%'
					/>
				</div>
			</div>

			<div className='header'>
				<p></p>
				<h2 className='heading'>{payload.id} / 10</h2>
				<Link to='/'>
					<h2 className='heading red'>X</h2>
				</Link>
			</div>
			<SwipeableViews
				enableMouseEvents
				index={index}
				onChangeIndex={changedIndex}
				onTransitionEnd={() => userAnswered(callback)}
				onSwitching={switching}
				className={'swipableContainer'}
			>
				{/*need an empty left div*/}
				<div />

				{/*need question div*/}
				<div className='questionBlock' id='qblock'>
					{image && <img style={{ width: '100%' }} alt='plaatje' src={image} />}

					{location.pathname === '/home' && youtubeId && (
						<YoutubePlayer videoId={youtubeId} />
					)}
				</div>

				{/*need an empty left div*/}
				<div />
			</SwipeableViews>
			{/* <div className="nav">
                <button>echt</button>
                <button>nep</button>
            </div> */}
		</div>
	);
};

export default Question;
