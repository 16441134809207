const links3 = [
	// {
	// 	description: 'Snap wat je stuurt',
	// 	intro: 'Online flirten. Dat kan heel leuk en spannend zijn. Maar snap wat je stuurt.',
	// 	url: 'https://www.rotterdam.nl/wonen-leven/snapje-wat-je-stuurt/',
	// },
	// {
	// 	description: 'Info phising',
	// 	intro: 'Weet waar je het over hebt en pak die info over phishing.',
	// 	url: 'https://www.politie.nl/themas/phishing.html',
	// },
	{
		description: 'Internetoplichting',
		intro: 'Meer info over internetoplichting.',
		url: 'https://www.politie.nl/themas/internetoplichting.html',
	},
	{
		description: 'Cybercriminaliteit',
		intro: 'Ook criminelen maken gebruik van digitale middelen.',
		url: 'https://www.rotterdam.nl/werken-leren/cybercriminaliteit/ ',
	},
	{
		description: 'Algemene informatie cybercrime',
		intro:
			'Voor algemene informatie over cybercrime kan je terecht bij de Rijksoverheid Nationaal Cyber Security Centrum.',
		url: 'https://veiliginternetten.nl/',
	},
];

export default links3;
