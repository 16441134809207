import Heading from 'components/Heading';
import React, {  useEffect, useState } from 'react';
import usePageContent from 'utils/pageChange';

import './download.scss';

const Download = () => {

	const { pageContent: page } = usePageContent();
	const [tabIndex, setTabIndex] = useState(false);

	useEffect(() => {
		console.log('---> download remove', page.current.link.replace('/', ''));
		if (page.current.link.replace('/', '') === "download"){
			setTabIndex(false);			
		}else{
			setTabIndex(true);
		}
	})


	return (
		<div className='download'>
			<Heading title='info' />
			<div className='content'>
				<div className='scroll'>
					<h3>over echt-nep</h3>
					<h4>
						Vooral jonge vrouwen tussen de 14 en 21 jaar lopen risico slachtoffer te worden van oplichting
						via internet, ook wel phishing genoemd.
					</h4>
					<p>
						De oplichter lokt het slachtoffer naar een valse webpagina om zo persoonlijke (bank)gegevens te
						verkrijgen. De campagne Echt-Nep moet deze jonge vrouwen bewust maken van de risico’s van het
						klikken op links die niet betrouwbaar zijn. De campagne laat hen ervaren wat er gebeurt als ze
						op een foute link klikken en geeft hen tips en trucs om te voorkomen dat ze slachtoffer worden.
					</p>
					<h3>Meer info of vragen</h3>
					<h4>Stuur een e-mail naar</h4>
					<button tabIndex={tabIndex} className='standardButton' href='mailto:cyber@gemeenterotterdam.nl'>
						cyber@rotterdam.nl
					</button>
					<br />
					<br />
					<h4>DOWNLOADS</h4>

					<div className='links'>
						<a tabIndex={tabIndex} href='docs/phising.pdf' target='_blank'>
							<div>
								<h4>Phishing infoblad (PDF)</h4>
								{/* <p>download een infoblad over phising</p> */}
							</div>
							<div>
								<h5>&gt;</h5>
							</div>
						</a>
						<a tabIndex={tabIndex} href='docs/Infoblad.pdf' target='_blank'>
							<div>
								<h4>Digitaal veilig (PDF)</h4>
								{/* <p>download </p> */}
							</div>
							<div>
								<h5>&gt;</h5>
							</div>
						</a>
					</div>
					{/* 
                    <div className="downloadBlad">
                        <h2>pak hier jouw gratis infoblad</h2>
                        <a
                            href={process.env.PUBLIC_URL + "/docs/Infoblad.pdf"}
                            target="_new"
                        >
                            <img src="/images/download.svg"></img>
                        </a>
                    </div> */}
					<div className='var'>
						<h4>De campagne (onderdeel van <a Style='color:black; text-decoration:underline' href="https://www.echt-nep.nl" Target="_new">echt-nep.nl</a>) is een initiatief van:</h4>
						<img src='/images/var.png' alt='var'></img>
						<img className='gemeente' alt='gemeente' src='/images/gemeente.svg'></img>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Download;
