const tips = [
	{
		header: 'deel nooit je wachtwoorden',
		subheader:
			'Deel je gebruikers&shy;namen en wachtwoorden nooit met andere mensen of bedrijven.',
		content:
			'Je bank of andere instantie zal nooit per e-mail of telefoon naar inloggegevens of andere persoonlijke codes vragen. Wordt hier wel naar gevraagd in een e-mail of telefoontje van iemand die zich voordoet als een medewerker van je bank of een andere instantie? Reageer er niet op en verwijder de e-mail. Deze gegevens zijn alleen van jou dus deel deze niet. Anderen kunnen met jouw gegevens aankopen doen of dingen op internet plaatsen waar je het niet mee eens bent.'
	},
	{
		header: 'Gebruik liever wachtzinnen',
		subheader: 'Gebruik wachtzinnen in plaats van wachtwoorden',
		content:
			'Het is belangrijk dat een wachtzin uit minstens 10 letters én leestekens bestaat. Het is nog veiliger als je bij het inloggen een extra beveiliging gebruikt (tweestapsverificatie). Er wordt dan een toegangscode naar een vertrouwd apparaat van jou gestuurd om in te kunnen loggen. Hackers hebben dan aan je gebruikers&shy;naam en wachtwoord alleen niet meer genoeg om toegang tot je account of cloud te krijgen.'
	},
	{
		header: 'Verbind alleen met wifi die je kent',
		subheader: 'Maak alleen verbinding met vertrouwde wifinetwerken',
		content:
			'Bij openbare en onbeveiligde wifinetwerken kunnen anderen mogelijk zien wat je op het internet doet en welke gegevens je verstuurt. Verstuur dus geen gevoelige gegevens (e-mail, internetbankieren) over netwerken die je niet kent of niet vertrouwt.  '
	},
	{
		header: 'Installeer alleen gecontroleerde apps',
		subheader:
			'Installeer alleen apps via de officiële applicatie&shy;winkels (APP STORE, GOOGLE PLAY etc.)',
		content: 'Deze apps zijn gecontroleerd en veilig te gebruiken.'
	},
	{
		header: 'Deel alleen met bekenden',
		subheader: 'Bedenk goed wat je met wie deelt op internet',
		content:
			'Zo gemakkelijk als het is om iets op internet te plaatsen, zo moeilijk is het om dit er weer af te krijgen. Denk dus goed na over wat je wel of niet op internet wilt delen. Scherm je socialenetwerksites (Instagram, Snapchat, TikTok, Facebook enz.) goed af en kijk goed uit wie toegang krijgt tot je profiel en gegevens. Laat je je gegevens ergens achter, ga dan na bij welke organisatie je dat doet, hoe lang je gegevens worden bewaard en aan wie deze nog meer kunnen worden verstrekt. Geef niet meer gegevens dan noodzakelijk is.'
	},
	{
		header: 'Gebruik je gezond verstand',
		subheader: 'Gebruik je gezond verstand',
		content:
			'Als iets te mooi lijkt om waar te zijn, dan is het dat meestal ook. Wees oplettend als je iets niet vertrouwt of niet kent.'
	}
];

export default tips;
