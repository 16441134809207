import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import DesktopBackground from './components/pages/DesktopBackground';
import Content from './components/pages/Content';
import Glitch from './components/pages/Glitch';
import Banner from './components/Banner';
import pages from 'config/pages';

import { QuizContext } from './QuizContext';
import { ProgressContext } from 'components/utils/QuizProgress';
import quizQuestions from './config/questions';
import useWindowDimensions from 'utils/windowDimensions';

import './App.scss';
import './Accessibility.scss';

import Menu from './components/pages/Menu';
import ByPassNav from 'components/utils/ByPassNav';

function App() {
	const page = {
		current: pages[0],
		previous: null
	};

	const [rw, rh] = [1000, 500];

	const [quizProgress, setQuizProgress] = useState(null);
	const [fade, setFade] = useState(false);
	const qProgress = { quizProgress, setQuizProgress };

	let { width: w, height: h } = useWindowDimensions();

	useEffect(() => {
		if ((w < rw || h < rh) && fade) {
			setFade(false);
			const appContainer = document.getElementById('app');
			if (appContainer) {
				if (window.innerHeight < rh || window.innerWidth < rw) {
					appContainer.style.top = 'initial';
					appContainer.style.right = 'initial';
				}
			}
		}
	}, [w, h]);

	const enlarge = () => {
		if (window.innerHeight > rh && window.innerWidth > rw && !fade) {
			setFade(true);
		}
	};

	const shrink = () => {
		setFade(false);

		const appContainer = document.getElementById('app');
		if (appContainer) {	
			if (window.innerHeight < rh || window.innerWidth < rw) {
				appContainer.style.top = 'initial';
				appContainer.style.right = 'initial';
			}
		}
	};

	return (
		<>
			<Router>
				<ByPassNav action={() => enlarge}></ByPassNav>	
				<DesktopBackground fade={fade} shrink={shrink} enlarge={enlarge} />
			
				<div
					className={`App ${fade ? 'enlarge' : ''}`}
					id='app'
					onMouseEnter={() => enlarge()}
					// onMouseLeave={() => shrink()}
					onClick={() => enlarge()}
				>
					<QuizContext.Provider value={{ quizQuestions, page }}>
						<ProgressContext.Provider value={qProgress}>
							{/* <Banner main={true} /> */}



							{typeof InstallTrigger === 'undefined' && <Banner />}
							<div className={`contentContainer`} id='contentContainer'>
								<Content className='cContent'></Content>
								<Menu className='cMenu'></Menu>
							</div>
							<Glitch></Glitch>
						</ProgressContext.Provider>
					</QuizContext.Provider>
				</div>
			
			</Router>
		</>
	);
}

export default App;
