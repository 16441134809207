import React from "react";

import "./content.scss";
import Carouesel3d from "components/utils/Carousel3d";

const Content = ({ expand, className }) => {
    return (
        <Carouesel3d
            delay={0}
            type={"content"}
            className={className}
            expand={expand}
        />
    );
};

export default Content;
