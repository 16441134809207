import React from 'react';
import './share.scss';

import { WhatsappShareButton, WhatsappIcon } from 'react-share';

const Share = () => {
	return (
		<div className='share'>
			<h3>deel deze quiz met je vrienden</h3>
			<div className='shareButtons'>
				<WhatsappShareButton
					url='https://www.echt-nep.nl'
					title='Kan jij echt van nep onderscheiden? 🤔 Doe de quiz!'
					separator=''
				>
					<div className='standardButton'>
						<p>DEEL VIA WHATSAPP</p>
						<WhatsappIcon size={32} round={true} />
					</div>
				</WhatsappShareButton>
			</div>
		</div>
	);
};

export default Share;
