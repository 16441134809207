import anime from "animejs/lib/anime.es.js";
import cnfg from "config/globals";

export default function Animate(frontDiv, speed, delay, direction, faces) {
    // can be refactorted
    const { _LEFT, _RIGHT, _FIRSTDIV, _SECONDDIV } = cnfg;
    const v = 92;

    const animate = transl => {
        anime({
            targets: ".cContent",
            zIndex: [
                { value: 11, round: true },
                { value: 100, round: true },
                { value: 9, round: true }
            ],
            easing: "linear",
            duration: speed
        });

        anime({
            targets: `#${faces.face1}`,
            rotateY: [
                { value: transl.div1Rot[0], delay, duration: 0 },
                { value: transl.div1Rot[1] }
            ],

            // opacity: [
            //     { value: transl.active[1], delay, duration: 800 },
            //     { value: transl.active[1], duration: 100 },
            //     { value: transl.active[0], duration: 100 },
            //     { value: transl.active[0], duration: speed }
            // ],
            translateZ: [
                { value: transl.div1X[0], delay, duration: 0 },
                { value: transl.div1X[1] }
            ],
            easing: "easeInOutQuint",

            duration: speed
        });

        anime({
            targets: `#${faces.face2}`,
            rotateY: [
                { value: transl.div2Rot[0], delay, duration: 0 },
                { value: transl.div2Rot[1] }
            ],

            // opacity: [
            //     { value: transl.active[0], delay, duration: 800 },
            //     { value: transl.active[0], duration: 100 },
            //     { value: transl.active[1], duration: 100 },
            //     { value: transl.active[1], duration: speed }
            // ],
            translateZ: [
                { value: transl.div2X[0], delay, duration: 0 },
                { value: transl.div2X[1] }
            ],
            easing: "easeInOutQuint",

            duration: speed
        });
    };

    if (frontDiv === _FIRSTDIV && direction === _RIGHT) {
        animate({
            div1Rot: [0, -90],
            div2Rot: [90, 0],
            div1X: [0, `${v}vw`],
            div2X: [`${v}vw`, 0],
            active: [0, 1]
        });
    }
    if (frontDiv === _FIRSTDIV && direction === _LEFT) {
        animate({
            div1Rot: [0, 90],
            div2Rot: [-90, 0],
            div1X: [0, `${v}vw`],
            div2X: [`${v}vw`, 0],
            active: [0, 1]
        });
    }
    if (frontDiv === _SECONDDIV && direction === _RIGHT) {
        animate({
            div1Rot: [90, 0],
            div2Rot: [0, -90],
            div1X: [`${v}vw`, 0],
            div2X: [0, `${v}vw`],
            active: [1, 0]
        });
    }
    if (frontDiv === _SECONDDIV && direction === _LEFT) {
        animate({
            div1Rot: [-90, 0],
            div2Rot: [0, 90],
            div1X: [`${v}vw`, 0],
            div2X: [0, `${v}vw`],
            active: [1, 0]
        });
    }
}
