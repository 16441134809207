import * as React from 'react';
import { useLocation } from 'react-router-dom';

import './glitch.scss';

export default function Glitch() {
	let location = useLocation();

	if (location.pathname !== '/fuik' && location.pathname !== '/gratisshoppen') {
		return <></>;
	}

	return (
		<div className='glitch'>
			{/* <ReactPlayer
                className="video"
                // url="movies/Glitch_9-16.mp4"
                url="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4"
                muted
                playsinline
                controls
                autoplay
            /> */}
			<video autoPlay muted playsInline id='v'>
				<source src='movies/1.mp4'></source>
			</video>
		</div>
	);
}
