import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './menu.scss';
import pages from '../../config/pages';
import parse from 'html-react-parser';
import { ProgressContext } from 'components/utils/QuizProgress';
import usePageContent from 'utils/pageChange';

const Menu = props => {
	const { pageContent: page } = usePageContent();
	const { quizProgress, setQuizProgress } = useContext(ProgressContext);
	const [displayQuizNav, setDisplayQuizNav] = useState(false);

	const answered = answer => {
		const { progress } = quizProgress;
		setQuizProgress({
			...quizProgress,
			progress: [...progress, answer],
			disabled: true
		});
	};

	useEffect(() => {
		setDisplayQuizNav(
			quizProgress
				? quizProgress.progress.length === 10
					? false
					: true
				: false
		);

		return () => {};
	}, [quizProgress]);

	return displayQuizNav ? (
		<div className='nav'>
			<button
				className='n1'
				disabled={quizProgress.disabled}
				onClick={() => answered(true)}
			>
				echt
			</button>
			<button
				className='n2'
				disabled={quizProgress.disabled}
				onClick={() => answered(false)}
			>
				nep
			</button>
		</div>
	) : (
		<div className={`menu ${page.current.to}M ${props.className}`}>
			{pages.map(({ id, to, menuTitle }) => {
				return (
					<Link to={to} key={id} id={to}>
						{parse(menuTitle)}
					</Link>
				);
			})}
		</div>
	);
};

export default Menu;
