import React from 'react';
import './Heading.scss';

const Heading = (props) => {
    return (
        <div className='header'>
            <h2 className="heading">{props.title.toUpperCase()}</h2>
        </div>        
    )
}

export default Heading